import React, { useState } from 'react'
import './style.scss';

export const TempdataDelete = (): JSX.Element => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const deleteIndexedDB = async () => {
    // IndexedDB のすべてのデータベースを削除
    const databases = await window.indexedDB.databases();
    for (const db of databases) {
      if (db.name) {
        window.indexedDB.deleteDatabase(db.name);
        console.log(`Deleted IndexedDB: ${db.name}`);
      }
    }
  };

  const handleDelete = async () => {
    // ローカルストレージの削除処理
    localStorage.clear();
    console.log('LocalStorage cleared.');

    // IndexedDB の削除
    await deleteIndexedDB();

    // 削除完了モーダルを表示
    setIsDeleteModalOpen(false);
    setIsConfirmModalOpen(true);
  };

  const handleReturn = () => {
    // 呼び出し元へ戻る処理（例えばページ遷移）
    window.history.back();
  };

  return (
    <div className="tempdelete-form">
      <div className="title">
        <img src="/img/td_Title.svg" alt="" />
      </div>
      <div className="description">
        <img src="/img/td_MessageDescription.svg" alt="" />
      </div>
      <div className="attention1">
        <img src="/img/td_desc1.svg" alt="" />
      </div>
      <div className="attention2">
        <img src="/img/td_desc2.svg" alt="" width="768" />
      </div>
      <div className="attention3">
        <img src="/img/td_caution.svg" alt="" />
      </div>
      <button
        className="button"
        onClick={() => setIsDeleteModalOpen(true)}
      >
        <img src="/img/td_btnText.svg" alt="" />
      </button>

      {/* 削除確認モーダル */}
      {isDeleteModalOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <h2><img src="/img/modal_title.svg" alt="一時情報を削除する" /></h2>
            <div className="msg">
              <img src="/img/modal_msg1.svg" alt="トモプラの一時情報を削除します。よろしいですか？" />
            </div>
            <div className="btnbg">
              <button className="delete-button" onClick={handleDelete}>
                <img src="/img/modal_delete_text.svg" alt="削除する" />
              </button>
              <button
                className="cancel-button"
                onClick={() => setIsDeleteModalOpen(false)}
              >
                <img src="/img/modal_cancel_Text.svg" alt="キャンセル" />
              </button>
            </div>
          </div>
        </div>
      )}

      {/* 削除完了モーダル */}
      {isConfirmModalOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <h2><img src="/img/modal_title.svg" alt="一時情報を削除する" /></h2>
            <div className="msg">
              <img src="/img/modal_msg2.svg" alt="削除しました。" />
            </div>
            <div className="btnbg">
              <button className="return-button" onClick={() => setIsConfirmModalOpen(false)}>
                <img src="/img/modal_close_text.svg" alt="とじる" />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>

  )
}
