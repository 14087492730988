import { DatePicker } from '@/src/components/DatePicker/DatePicker';
import clsx from 'clsx';
import { startOfYesterday } from 'date-fns';
import { Controller, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import styles from '../styles.module.css';
import { Label } from './Label';

type FormItemDateRangePickerProps = {
  className?: string;
  label?: React.ReactNode;
  labelWidth?: number;
  fromName?: string;
  toName?: string;
  noRuby?: boolean;
  disabled?: boolean;
};

const Div = styled('div')(
  ({ theme }) => `
    &.date-range-picker{
      .MuiTextField-root{
        background: white;
        border-radius: 4px;
      }
      input{
        color:var(${theme.textlabel})!important
      }
    }
  `,
);

export const FormItemDateRangePicker = ({
  className,
  label = LABEL,
  labelWidth,
  fromName = 'from_date',
  toName = 'to_date',
  disabled,
  noRuby,
}: FormItemDateRangePickerProps) => {
  const { control } = useFormContext();
  return (
    <Label
      className={clsx(className, !noRuby && styles['ruby'])}
      label={label}
      labelWidth={labelWidth}
    >
      <Div className={`${styles['date-range-picker']} date-range-picker`}>
        <Controller
          name={fromName}
          control={control}
          render={({ field }) => (
            <DatePicker
              className={styles['date-picker']}
              {...field}
              disabled={disabled}
              value={disabled ? new Date(new Date().getFullYear(), 3, 1) : field.value}
              //JavaScript の Date オブジェクトでは月が 0 始まりのため、4 月は 3
              minDate={new Date(2024, 3, 1)}
              maxDate={startOfYesterday()}
            />
          )}
        />
        <span>〜</span>
        <Controller
          name={toName}
          control={control}
          render={({ field }) => (
            <DatePicker
              className={styles['date-picker']}
              {...field}
              disabled={disabled}
              value={disabled ? startOfYesterday() : field.value}
              minDate={new Date(2024, 3, 1)}
              maxDate={startOfYesterday()}
            />
          )}
        />
      </Div>
    </Label>
  );
};

const LABEL = (
  <div>
    <ruby>
      期<rt>き</rt>
    </ruby>
    <ruby>
      間<rt>かん</rt>
    </ruby>
    をえらぶ
  </div>
);
